import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useToggledQuery from '../../useToggledQuery';
import {GET_UNDERWRITING_REQUESTS} from '../../../queries';

export default function useRenovationUnderwritingRequests({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_UNDERWRITING_REQUESTS,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, error, loading, fetch} = response;

  const underwritingRequests = propOr(
    [],
    'renovationUnderwritingRequests',
    data,
  );

  return {
    fetch,
    underwritingRequests,
    loading,
    error,
  };
}
