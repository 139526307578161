import React from 'react';

import Root from 'modules/root';

import {initializeApp} from '@renofi/modules-internal';
import initPolyfills from '@renofi/utils/src/polyfills';

import * as appConfig from './config';
import initAnalytics from './analytics';
import './index.css';

initializeApp({
  appConfig,
  onInit: async ({config, storage}) => {
    initPolyfills();
    await initAnalytics({config, storage});
  },
  rootComponent: <Root />,
});
