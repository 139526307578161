import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useQuery from '../useQuery';
import {GET_RENOVATION_LENDER_REPORT} from '../../queries';

export default function useLenderReport({
  fetchPolicy = 'cache-first',
  onCompleted = noop,
  ...options
} = {}) {
  const response = useQuery(GET_RENOVATION_LENDER_REPORT, {
    onCompleted,
    fetchPolicy,
    ...options,
  });

  const {data, error, loading, fetch} = response;
  const lenderReport = propOr(null, 'lenderReport', data);

  return {error, fetch, lenderReport, loading};
}
