import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {SUBMIT_RENOVATION_UNDERWRITING_REQUEST} from '../../../mutations';

export default function useSubmitRenovationUnderwritingRequest(
  onCompleted = noop,
  refetchQueries = [],
) {
  const [submitRenovationUnderwritingRequest, {data, loading, error}] =
    useMutation(SUBMIT_RENOVATION_UNDERWRITING_REQUEST, {
      onCompleted,
      refetchQueries,
    });

  const response = propOr(null, 'submitRenovationUnderwritingRequest', data);
  return {submitRenovationUnderwritingRequest, response, loading, error};
}
