import isImpersonation from '@renofi/utilities/src/isImpersonation';
import logger from '@renofi/utilities/src/logger';
import {
  initAccessibe,
  initDataDog,
  initSegment,
  initSentry,
  isTestSession,
} from '@renofi/analytics';

export default async ({config, storage}) => {
  const {DEVELOPMENT, PRODUCTION} = config;
  const impersonation = isImpersonation(storage);

  window.addEventListener('error', (error) => {
    logger.error('ERROR', error);
  });

  initDataDog();

  if (impersonation || isTestSession()) return;

  if (!DEVELOPMENT) {
    initSegment({
      integrations: {All: true, 'Bing Ads': false, 'Facebook Pixel': false},
    });
  }

  if (PRODUCTION) {
    initSentry();
    initAccessibe();
  }
};
